.InfoMessageCard {
  margin-bottom: 20px;
  background-color: #6259ca;
  background-image: url('../../../../src/assets/img/patternpad_purple.svg');
  background-size: cover;
  border-radius: 20px;
  h2 {
    font-size: 25px;
    color: #fff;
    margin-left: 30px;
    z-index: 4;
  }
  h3 {
    font-size: 20px;
    color: #fff;
    z-index: 4;
    margin-left: 30px;
  }

  position: relative;
}
.InfoMessageCard__img {
  height: 170px;
  position: absolute;
  right: 0;
  top: -70px;
  z-index: 1;
}
