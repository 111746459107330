@import '../../variables.scss';

.kemi_form_input {
  border: 1px solid #d9d9d9;
  background: #ffffff !important;
  font-weight: 600;
  .ant-form-item-label > label {
    color: $dark-blue;
    font-size: 16px;
  }
  &.ant-input:focus {
    border-color: #423aa7;
    box-shadow: 0 0 0 2px rgba(90, 84, 177, 0.1);
  }
  &.ant-input:hover {
    border-color: #423aa7;
    box-shadow: 0 0 0 2px rgba(66, 58, 170, 0.2);
  }
}

.kemi_form_select {
  border-radius: 6px;
  font-weight: 600;
  .ant-form-item-label > label {
    color: $dark-blue;
    font-size: 16px;
  }

  .ant-select-arrow {
    svg {
      color: #423aa7 !important;
    }
  }
}

.kemi_form_select_purple {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  font-weight: 600;
  width: 100%;
  max-width: 550px;

  .ant-form-item-label > label {
    color: $dark-blue;
    font-size: 16px;
  }
  .ant-select-arrow {
    font-size: 20px;
    color: $dark-purple;
    margin-top: -10px;
  }
  .ant-select-selector {
    background-color: #f3f2ff !important;
    margin: 10px 0 5px 23px;
  }
}

.kemi_form_input_grey {
  background-color: #e5e7eb !important;
  border-radius: 6px;
}

// To remove the autofill background color in input fields
input,
select {
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.kemi_form_select_grey {
  &:focus {
    background-color: #e5e7eb;
    border-radius: 6px;
    border: 0;
  }
  .ant-select-selector {
    border-radius: 6px !important;
    border: 0;
    background-color: #e5e7eb !important;
  }
}

.ant-select-dropdown {
  border-radius: 6px;
}

.kemi_form_highlighted_header {
  margin-bottom: 20px;
  background-color: #fff2c3;
  border-radius: 6px;
  font-size: 17px;
  color: $dark_blue;
  padding: 5px 0px 5px 10px;
  margin-block-start: unset !important;
}

.kemi_form_info_icon {
  font-size: 13px;
  color: $dark-purple;
}
.ant-collapse svg {
  color: #6259ca !important;
}

.kemi_form_btn_green {
  border: 0;
  border-radius: 6px;
  background-color: #387947;
  color: #fff;
  &:hover {
    transition: 0.3s;
    color: #fff !important;
    background-color: #1f7032 !important;
  }
  &:focus {
    transition: 0.3s;
    color: #fff !important;
    background-color: #1f7032 !important;
  }
}

.kemi_form_btn_purple {
  border: 0;
  border-radius: 6px;
  background-color: #585393;
  color: #fff;
  &:hover {
    transition: 0.3s;
    color: #fff !important;
    background-color: #413b89 !important;
  }
  &:focus {
    transition: 0.3s;
    color: #fff !important;
    background-color: #413b89 !important;
  }
}

.kemi_form_btn_dark {
  font-size: 18px;
  height: 35px;
  width: auto;
  border: 0;
  border-radius: 6px;
  background-color: $dark-blue;
  color: #fff;
  &:hover {
    background-color: $dark-purple !important;
    color: #fff !important;
    transition: 0.3s;
  }
  &:focus {
    background-color: $dark-purple !important;
    color: #fff !important;
    transition: 0.3s;
  }
}

.kemi_form_icon_button_ghost {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.kemi_login_tab {
  min-height: 97vh;
  .ant-tabs-ink-bar {
    background-color: $dark-purple;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $dark-blue;
  }
  .ant-tabs-tab:hover {
    color: $dark-purple;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 3px;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border: 0;
  }
}

.fixed_width_input {
  width: 230px;
}

.form_btn_margin {
  margin: 0 3px 0 3px;
}

.kemi_form_background {
  background-color: #f7f7f9;
  border-radius: 6px;
  padding: 5px;
}

.kemi_form_textarea {
  border-radius: 5px;
  width: 100%;
  max-width: 800px;
}

.kemi_form_input_uppercase {
  text-transform: uppercase;
}

.kemi_form_select_wide_dropdown {
  min-width: 30% !important;
  border-radius: 0px 6px 6px 6px;
}

.kemi_info_btn_purple {
  border: 0;
  border-radius: 6px 6px 0 0;
  color: $dark-purple;
  &:hover {
    transition: 0.3s;
    background-color: $lightest-purple !important;
    color: $dark-purple !important;
  }
  &:focus {
    transition: 0.3s;
    background-color: $lightest-purple !important;
    color: $dark-purple !important;
  }
  &_active {
    @extend .kemi_info_btn_purple;
    background-color: $lightest-purple;
  }
}

.kemi_info_menu {
  .ant-menu {
    background: none;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }
}

.kemi_form_link {
  color: $dark-purple;
  text-decoration: none;
  &:hover {
    color: #8f87ea;
  }
}

.kemi_select_company_input {
  max-width: 230px;
}
