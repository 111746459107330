.sidebar__ {
  // classes
  .sidebar__brand {
    height: 60px;
    > .ant-image {
      display: flex;
      justify-content: center;
    }
  }
  .__user_information {
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      margin: 0;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .sidebar__menu {
    color: #a29ece;
    background-color: unset;
    margin-top: 7%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .sidebar__menu-icon {
      color: #fff;
      width: 2.5rem;
      height: 2.5rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      border-radius: 50%;
    }
    span {
      font-size: 20px;
      letter-spacing: 0.2px;
    }
  }

  // overrides
  .ant-menu-item {
    height: 50px;
    color: #a29ece !important;
    transition: unset !important;
    margin-top: 0px !important;
    margin-inline-start: 1.5vw !important;
    padding-left: 7px !important;
    width: 13.7vw;
    margin-block: 0px !important;
  }
  .ant-menu-item:not(.ant-menu-item-selected):hover {
    background-color: rgb(255 255 255 / 6%);
    border-radius: 30px;
  }
  .ant-menu-item:not(.ant-menu-item-selected) {
    .sidebar__menu-icon {
      color: #a29ece !important;
    }
  }
  &.ant-layout-sider {
    background-color: #25233c !important;
  }
  .ant-menu-item-active {
    background-color: #eaedf7 !important;
    color: #a29ece !important;
    border-radius: 57px 0 0 57px !important;
    svg {
      background-color: #6259ca;
      color: #fff;
    }
  }

  .ant-menu-item-selected {
    width: 90%;
    height: 50px;
    background-color: #eaedf7 !important;
    overflow: visible;
    transition: unset !important;
    border-radius: 57px 0 0 57px;
    box-shadow: 4px 0px 0 0 #eaedf7;
    & span {
      font-size: 20px;
      color: #5a53b8 !important;
    }
    & svg {
      background-color: #6259ca;
      color: #fff;
    }
  }

  .ant-menu-item-selected::after {
    content: '';
    position: absolute !important;
    background-color: transparent;
    left: 90%;
    inset-block: -50px !important;
    height: 50px;
    width: 10%;
    border-bottom-right-radius: 25px;
    box-shadow: 2px 25px 0 0 #eaedf7;
    transition: unset !important;
  }
  .ant-menu-item-selected::before {
    content: '';
    position: absolute;
    background-color: transparent;
    left: 90%;
    bottom: -50px;
    height: 50px;
    width: 10%;
    border-top-right-radius: 25px;
    box-shadow: 2px -25px 0px 0px #eaedf7;
  }

  .ant-menu-inline {
    border-inline-end: none !important;
  }

  // SUB MENU PARTS
  .ant-menu-submenu-title {
    color: #a29ece !important;
    margin-inline-start: 1.5vw !important;
    padding-left: 7px !important;
    background-color: transparent !important;
    .ant-menu-item-icon {
      color: #a29ece !important;
    }

    .ant-menu-title-content {
      margin-right: 0.5vw !important;
    }
  }

  .ant-menu-sub {
    .ant-menu-title-content {
      margin-left: 1vw !important;
      font-size: 18px !important;
    }
    @media (min-width: 1600px) and (max-width: 1700px) {
      .ant-menu-title-content {
        font-size: 16px !important;
      }
    }
    .ant-menu-item {
      width: 12.7vw !important;
      transform: translateX(35px);
      margin-block: 4px !important;
    }
    @media (min-width: 1600px) and (max-width: 1700px) {
      .ant-menu-item {
        width: 12.4vw !important;
      }
    }
    @media (min-width: 1700px) and (max-width: 1800px) {
      .ant-menu-item {
        width: 12.5vw !important;
      }
    }
    .ant-menu-item-selected {
      height: 50px;
      background-color: #eaedf7 !important;
      overflow: visible;
      border-radius: 57px 0 0 57px;
      box-shadow: 4px 0px 0 0 #eaedf7;
      transform: translateX(35px);
    }
    .ant-menu-item-selected::after {
      content: '';
      position: absolute !important;
      background-color: transparent;
      left: 82%;
      inset-block: -50px !important;
      height: 50px;
      width: 10%;
      border-bottom-right-radius: 25px;
      box-shadow: 2px 25px 0 0 #eaedf7;
      transition: unset !important;
    }
    .ant-menu-item-selected::before {
      content: '';
      position: absolute;
      background-color: transparent;
      left: 82%;
      bottom: -50px;
      height: 50px;
      width: 10%;
      border-top-right-radius: 25px;
      box-shadow: 2px -25px 0px 0px #eaedf7;
      transition: unset !important;
    }
  }
  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      color: #fff !important;
      .ant-menu-item-icon {
        color: #fff !important;
      }
    }
  }

  .ant-menu .ant-menu-submenu-arrow {
    inset-inline-end: 30px !important;
  }
}

.ant-layout-sider-collapsed {
  .ant-menu-item {
    width: calc(100% - 19px);
    overflow: hidden !important;
    margin-inline-start: 1.1vw !important;
  }
  .ant-menu-submenu-title {
    margin-inline-start: 1.1vw !important;
  }
  .ant-badge {
    display: none !important;
  }
  .ant-menu-item-icon {
    margin-top: 5px !important;
  }
  .ant-menu-item-selected {
    overflow: hidden !important;
    width: calc(100% - 19px);
  }
  .ant-menu-item-active {
    overflow: hidden !important;
    width: calc(95% - 11px);
  }
  .ant-menu {
    margin-top: 0px !important;
  }
}
